import Vue from "vue";
import VueRouter from "vue-router";
import {getToken, checkIfLoggedIn, clearCookies} from "../utils/helpers"

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () =>
        import("../views/loginPage.vue"),
    redirect: "/login",
    children: [
      {
        path: "login",
        name: "login",
        component: () =>
        import("../views/loginPage.vue"),
      },
    ],
  },
  {
      path: "/register",
      name: "register",
      component: () =>
      import("../views/Register.vue"),
    },
    {
      path: "/verify",
      name: "verify",
      component: () =>
      import("../views/VerifyEmail.vue"),
    },
    {
      path: "/forgot",
      name: "forgot",
      component: () =>
      import("../views/ForgotWeb.vue"),
    },
    {
      path: "/reset",
      name: "reset",
      component: () =>
      import("../views/ResetPassword.vue"),
    },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;

router.beforeEach((to, from, next) => {
  document.title = `SMC`;
  let userdata = localStorage.getItem("simpAdminState") || null;
  let userval = userdata ? JSON.parse(userdata).userVUEX.userData : {};
  if((to.name == "verify" && to.query.type == 'verify') || to.name == "reset"){
    if(Object.keys(userval).length == 0){
    next({ path: "login" });
    }
  }
  let isLaptop = window.innerWidth >= 1024 ? true : false;
  // next();
  if(!getToken()){
    clearCookies();
    next();
  }
  else{
    checkIfLoggedIn(to,isLaptop, next);
  }
  // else{
  //   // clearCookies();
  //   next();
  // }
  });
